
import { Component, OnInit, HostListener, ViewChild, ElementRef, Renderer2 } from '@angular/core';  
import { trigger, state, transition, style, animate } from '@angular/animations';  
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations:[ 
    trigger('fade',
    [ 
      state('void', style({ opacity : 0})),
      transition(':enter',[ animate(300)]),
      transition(':leave',[ animate(500)]),
    ]
  )]
})
export class HeaderComponent implements OnInit {

  //@ViewChild("navHeader") navHeader!: ElementRef;

  // constructor(private render: Renderer2) { }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(event:any) {
  //   console.debug("onWindowScroll: ",event,window);
  //   //console.debug("navHeader:",this.navHeader.nativeElement);
  //   if (window.pageYOffset > 550) {
  //     this.render.addClass(this.navHeader.nativeElement,"sticky");
  //   } else {
  //     this.render.removeClass(this.navHeader.nativeElement,"sticky");
  //   }
  // }

  public openMe:boolean = false;

  constructor(){

  }

  ngOnInit(): void {
  }

  toggleMenu(){
    this.openMe = !this.openMe;
  }

  onClickOutside(e:Event){
    this.openMe = false;
  }

}
