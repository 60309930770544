<footer id="footer" class="page-section pt-80 pb-50 footer2-black">
    <div class="container">
        <div class="">
            <a href="https://www.facebook.com/Thaiphoon-Bistro-135753346454713"   target="_blank"><img src="assets/img/facebook.png"></a>
        </div>
<div class="row footer ">
    <div class="col-lg-4  col-sm-12 footer-info ">
        <h5>Thaiphoon Bistro - Gateway Commons</h5>
        <p>1704-A E. Franklin St. </p>
        <p>Chapel Hill, NC 27514</p>
        <p>Tel. 919-869-7191</p>
        <p><a href="https://goo.gl/maps/Dux6w7DrBm8C49mA6"  target="_blank">
            <span class="material-symbols-outlined">near_me</span>Directions to Gateway Commons</a></p>
    </div>
    <div class="col-lg-4  col-sm-12 footer-info ">
        <h5>Thaiphoon Bistro - Glenwood Center</h5>
        <p>301 Glenwood avenue, Suite 190</p>
        <p>Raleigh, NC 27603</p>
        <p>Tel. 919-720-4034</p>
        <p><a href="https://goo.gl/maps/2QBqEoKA72Fu9qTCA"  target="_blank">
            <span class="material-symbols-outlined">near_me</span>
            Directions to Glenwood Center</a></p>
    </div>
    <div class="col-lg-4  col-sm-12 footer-info ">
        <h5>Thaiphoon Bistro - Stonhenge Market</h5>
        <p>7496 Creedmoor road</p>
        <p>Raleigh, NC 27613</p>
        <p>Tel. 919-917-7988</p>
        <p><a href="https://goo.gl/maps/JF4xG27LCnrpLYxX9"  target="_blank"><span class="material-symbols-outlined">near_me</span>Directions to Stonhenge Market</a></p>
    </div>
    <div class="col-lg-4  col-sm-12 footer-info ">
        <h5>Thaiphoon Bistro - The Flavor District</h5>
        <p>11016 Capital Blvd STE.17</p>
        <p>Wake Forest, NC 27587</p>
        <p>Tel. 919-263-8837</p>
        <p><a href="https://goo.gl/maps/QWKWdKoe4m6g66ht5"  target="_blank"><span class="material-symbols-outlined">near_me</span>Directions to The Flavor District</a></p>
    </div>


</div>
<div class="footer d-flex justify-content-center">
    <div class="footer-info copyright">

        <p>COPYRIGHT 2021 - ALL RIGHT RESERVED. DESIGNED BY <a href="http://dolosplus.com/" target="_blank">DOLOS PLUS</a></p>
        <p class="revision">updated 03-29-2023</p>
    </div>
</div>

    <!-- <a class="goback" aria-label="Contact" [routerLink]="['home']" [fragment]="'navbar'">
        <img src="assets/img/up.png">
    </a> -->


</div>
</footer>