<!-- <header class="animated fadeInDown" id="navbar" #navHeader>  -->
<header class="animated fadeInDown" id="navbar">
<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top navbar-custom" (clickOutside)="onClickOutside($event)">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" (click)="toggleMenu()"
      data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse"  [ngClass]="{'show': openMe}"   id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <a class="nav-link" routerLinkActive="active" aria-label="Home" aria-current="Home" [routerLink]="['home']">Home</a>
          <span class="separator">&#9679;</span>
          <a class="nav-link"  routerLinkActive="active" aria-label="Download menu in PDF" tooltip="Open PDF menu" [routerLink]="['menu']">Menu</a>
          <span class="separator">&#9679;</span>
          <a class="nav-link" aria-label="Order from Grubhub" target="_blank" tooltip="Open Grubhub" href="https://www.grubhub.com/restaurant/thaiphoon-bistro-301-glenwood-ave-raleigh/479168?orderMethod=pickup&utm_source=printed-qrcode_in-restaurant&utm_medium=offlineprint_partner&utm_campaign=pickup_instorecontactless_landing-menu_doorsign&utm_term=479168&fbclid=IwAR0qyz_rAF4elCTuVj8vKW7URVFT98w_YMykXyXN1Z2kQ4uahHWtSvqzFxU">Order</a>
          <span class="separator">&#9679;</span>
          <a class="nav-link"   aria-label="Photos" [routerLink]="['home']" [fragment]="'photos'">Photos</a>
          <span class="separator">&#9679;</span>
          <a class="nav-link" aria-label="Contact" [routerLink]="['home']" [fragment]="'footer'">Contact</a>
          
        </div>
      </div>

    </div>
  </nav>
</header>