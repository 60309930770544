import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public pdfSrc:string="";

  constructor() { }

  ngOnInit(): void {

    this.pdfSrc = "assets/menu.pdf";
  }

}
