<div id="slideshow">
<carousel [isAnimated]="true" >
    <slide>
      <img src="assets/img/slide1.jpg" alt="Pineapple Fried Rice" style="display: block; width: 100%;">
      <div class="carousel-caption d-none d-md-block">
        <h1 class="display-6">Welcome to Thaiphoon Bistro</h1>
        <p>Come in or order Togo, our delicious Pineapple Fried Rice is happily awaiting for you to enjoy</p>
      </div>
    </slide>
    <slide>
      <img src="assets/img/slide2.jpg" alt="second slide" style="display: block; width: 100%;">
      <div class="carousel-caption d-none d-md-block">
        <h1 class="display-1 ">SOM TOM - ส้มตำ</h1>
        <p>Give it a try our freshly made Papaya Salad pair with Thai Chicken Wings</p>
      </div>
    </slide>
    <slide>
      <img src="assets/img/slide3.jpg" alt="third slide" style="display: block; width: 100%;">
      <div class="carousel-caption d-none d-md-block">
        <h1 class="display-1 black">PAD HKI MAO -  ผัดขี้เมา</h1>
        <p class="">Feeling like spicy noodle, our Drunken Noodle is kicking and delightful lunch.</p>
      </div>
    </slide>
  </carousel>
</div>