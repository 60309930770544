<app-slide></app-slide>

<div class="container-fluid section-intro">
    <div class="row align-items-center ">
        <div class="col col-story ">
            <h1 class="">
                <span class="title">O&nbsp;u&nbsp;r&nbsp;&nbsp;&nbsp;S&nbsp;t&nbsp;o&nbsp;r&nbsp;y</span>
            </h1>
            <img src="assets/img/magnificent.png">
            <span class="story ">
                <p>
                    With so many ethnic restaurants in America, Thaiphoon Bistro in Raleigh, NC started out
                    as the first Thai restaurant in Downtown Raleigh (Glenwood South) in 2008 that served
                    a handful of dishes. But through the preserverance and hard work of the founder,
                    it as become a well-known place for amazing Thai food.
                </p>

                The menu at Thaiphoon Bistro has been crafted one dish at a time, perfecting each recipe
                along the way to encompass cuisine from every region in Thailand.

                Recommeded dishes include the Papaya Salad, the Green Curry and any of the flavorful noodle dishes.
                Thaiphoon Bistro also offers one of the largest vegetarian menus, including appetizers, soups,
                noodle dishes and selections over rice. Thaiphoon Bistro servers simple but tasty Thai food
                that has gained true notoriety in the City of Oaks.
            </span>
            <h5>LOCATIONS</h5>
            <div class="d-flex flex-column">
                <div class="d-flex justify-content-center align-items-center">
                    <p class="mx-2"><a href="https://goo.gl/maps/2QBqEoKA72Fu9qTCA"  target="_blank">Glenwood Center</a></p>
                    <img src="assets/img/logo-round.png">
                    <p class="mx-2"><a href="https://goo.gl/maps/JF4xG27LCnrpLYxX9"  target="_blank">Stonhenge Market</a></p>
                </div>
                <p class="mt-2"><a href="https://goo.gl/maps/Dux6w7DrBm8C49mA6"  target="_blank">Gateway Commons</a></p>
            </div>

        </div>
        <div class="col">
            <div class="container">           
                <div class="photo-box shadow1"></div>
            </div>
        </div>

    </div>
</div>

<section class="section-photos" id="photos">
    <div class="container ">
    <img src="assets/img/1.jpg" class="shadow">
    <img src="assets/img/2.jpg" class="shadow1">
    <img src="assets/img/3.jpg">
    <img src="assets/img/4.jpg">
    <img src="assets/img/5.jpg">
    <img src="assets/img/6.jpg">
    <img src="assets/img/7.jpg">
    <img src="assets/img/8.jpg">
    <img src="assets/img/9.jpg">
    </div>
</section>
